<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>
<style lang="less">
#app {
  min-height: 100vh;
  font-size: 20px;
  color: #333;
  font-family: '微软雅黑';
  .en {
    font-family: Arial;
    font-style: normal;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.v-modal {
  opacity: 0.2;
}
a {
  color: inherit;
  text-decoration: none;
}

a[href]:hover {
  color: inherit;
}

a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
}
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.van-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
