/*
 * @Descripttion: 数字格式化 数字转换成字母
 * @version:
 * @Author: xiaolanchong
 * @Date: 2020-12-22 13:32:48
 * @LastEditors: xiaolanchong
 * @LastEditTime: 2021-08-18 14:05:35
 */

import Vue from 'vue';
// 数字格式化
Vue.filter('numberFilter', (num = 0, length = 1) => {
  if (String(num).length > length) {
    return num;
  }
  return (Array(length).join('0') + num).slice(-length);
});

// 0 1 2 => A B C 数字转换成字母
Vue.filter('letterFilter', (num) => {
  if (num >= 0 && num <= 25) {
    // 65 => A 范围0 - 25
    return String.fromCharCode(65 + parseInt(num, 10));
  }
  return num;
});

// 最大显示
Vue.filter('numberMaxFilter', (num, max) => {
  if (num > max) {
    return max;
  }
  return num;
});

// 单位格式化
Vue.filter('unitFilter', (val) => {
  if (val < Math.pow(10, 4)) {
    return val; // 0-万
  } else if (val >= Math.pow(10, 4) && val < Math.pow(10, 6)) {
    return `${Math.floor(val / Math.pow(10, 3)) / 10}万`; // 万~百万
  } else if (val >= Math.pow(10, 6) && val < Math.pow(10, 7)) {
    return `${Math.floor(val / Math.pow(10, 6))}百万`; // 百万~千万
  } else if (val >= Math.pow(10, 7) && val < Math.pow(10, 8)) {
    return `${Math.floor(val / Math.pow(10, 7))}千万`; // 千万~亿
  } else if (val >= Math.pow(10, 8)) {
    return `${Math.floor(val / Math.pow(10, 8))}亿`; // >亿
  }
});
