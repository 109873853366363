import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
Vue.config.productionTip = false;

import Element from 'element-ui';
Vue.use(Element);
import './styles/index.less';
import '@/filters';

import xldImage from '@/component/xld-image';
Vue.use(xldImage);

import request from './utils/request';
Vue.prototype.$http = request;
Vue.prototype.env = process.env;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
