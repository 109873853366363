<template>
  <img :src="`https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/${path}/${name}.${type}`" :alt="name" />
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    type: {
      type: String,
      default() {
        return 'png';
      }
    },
    path: {
      type: String,
      default() {
        return 'common/icons';
      }
    },
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(['theme'])
  }
};
</script>

<style scoped>
img {
  display: inline-block;
  object-fit: contain;
}
</style>
