/*
 * @Descripttion: 文本过滤器
 * @version:
 * @Author: xiaolanchong
 * @Date: 2020-12-22 13:32:48
 * @LastEditors: xiaolanchong
 * @LastEditTime: 2021-03-22 18:29:06
 */

import Vue from 'vue';

// 文字超出省了号
Vue.filter('textEllipsis', (str, format) => {
  if (str && format) {
    const temp = str.slice(0, format);
    return str.length > temp.length ? `${temp}…` : temp;
  }
  return str || '';
});
