/*
 * @Descripttion: 设置token  localStorage sessionStorage
 * @version:
 * @Author: xiaolanchong
 * @Date: 2020-11-05 09:25:28
 * @LastEditors: xiaolanchong
 * @LastEditTime: 2020-12-25 10:04:03
 */
const AuthToken = 'officialWebsiteToken';
export function getToken() {
  return localStorage.getItem(AuthToken);
}
export function setToken(token) {
  return localStorage.setItem(AuthToken, token);
}
export function removeToken() {
  return localStorage.removeItem(AuthToken);
}

const namespace = 'ow_';
export function setLocal(key, data) {
  const obj = JSON.stringify({ key: data });
  localStorage.setItem(namespace + key, obj);
}
export function getLocal(key) {
  let value = localStorage.getItem(namespace + key);
  value = JSON.parse(value) || {};
  return value.key;
}
export function removeLocal(key) {
  localStorage.removeItem(namespace + key);
}

export function setSession(key, data) {
  const obj = JSON.stringify({ key: data });
  sessionStorage.setItem(namespace + key, obj);
}
export function getSession(key) {
  let value = sessionStorage.getItem(namespace + key);
  value = JSON.parse(value) || {};
  return value.key;
}
export function removeSession(key) {
  sessionStorage.removeItem(namespace + key);
}
