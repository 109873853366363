import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
import request from '@/utils/request';
export default new Vuex.Store({
  state: {
    relevantInfo: {},
    maleUserInfo: {},
    femaleUserInfo: {},
    targetUserInfo: {}
  },
  getters: {
    relevantInfo: (state) => state.relevantInfo,
    maleUserInfo: (state) => state.maleUserInfo,
    femaleUserInfo: (state) => state.femaleUserInfo,
    targetUserInfo: (state) => state.targetUserInfo
  },
  mutations: {
    SET_RELEVANT_INFO(state, data) {
      state.relevantInfo = data;
      const { isPair, sex } = data;
      const userRelevantFamilyInfo = data.userRelevantFamilyInfo || {};
      if (isPair === 1) {
        const maleUserInfo = {
          headImgUrl: userRelevantFamilyInfo.maleHeadImgUrl,
          nickName: userRelevantFamilyInfo.maleNickName,
          userId: userRelevantFamilyInfo.maleUserId,
          sex: 1,
          vipLevel: userRelevantFamilyInfo.vipLevel
        };
        const femaleUserInfo = {
          headImgUrl: userRelevantFamilyInfo.femaleHeadImgUrl,
          nickName: userRelevantFamilyInfo.femaleNickName,
          userId: userRelevantFamilyInfo.femaleUserId,
          sex: 2,
          vipLevel: userRelevantFamilyInfo.vipLevel
        };
        state.maleUserInfo = maleUserInfo;
        state.femaleUserInfo = femaleUserInfo;
        if (sex == 1) {
          state.targetUserInfo = femaleUserInfo;
        }
        if (sex == 2) {
          state.targetUserInfo = maleUserInfo;
        }
      } else {
        state.maleUserInfo = {};
        state.femaleUserInfo = {};
        state.targetUserInfo = {};
      }
    }
  },
  actions: {
    getRelevantInfo({ state, commit }, cause) {
      state.updateCause = cause;
      return request.get('/user/info/relevantInfo').then((res) => {
        commit('SET_RELEVANT_INFO', res.data || {});
        return res;
      });
    }
  }
});
