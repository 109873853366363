import Vue from 'vue';
import VueRouter from 'vue-router';
// import Page from '@/views/layouts/page';
Vue.use(VueRouter);

const routes = [
  {
    // 首页
    path: '/',
    name: 'home',
    meta: { title: '小楼东-让天下没有孤单的灵魂' },
    component: () => import(/* webpackChunkName: "home" */ '../views/home/index')
  },
  {
    // 关于产品
    path: '/aboutProduct',
    name: 'aboutProduct',
    meta: { title: '产品介绍-小楼东' },
    component: () => import(/* webpackChunkName: "home" */ '../views/aboutProduct/index')
  },
  {
    // 解决方案
    path: '/solution',
    name: 'solution',
    meta: { title: '解决方案-小楼东' },
    component: () => import(/* webpackChunkName: "home" */ '../views/solution/index')
  },
  {
    // 关于我们
    path: '/aboutUs',
    name: 'aboutUs',
    meta: { title: '关于我们-小楼东' },
    component: () => import(/* webpackChunkName: "home" */ '../views/aboutUs/index')
  },
  {
    // 联系我们
    path: '/contactUs',
    name: 'contactUs',
    meta: { title: '联系我们-小楼东' },
    component: () => import(/* webpackChunkName: "home" */ '../views/aboutUs/contact-us')
  },

  {
    // 联系我们
    path: '/joinUs',
    name: 'joinUs',
    meta: { title: '加入我们-小楼东' },
    component: () => import(/* webpackChunkName: "home" */ '../views/aboutUs/contact-us')
  },
  {
    // 去玩一下
    path: '/goPlay',
    name: 'goPlay',
    meta: { title: '去玩一下-小楼东' },
    component: () => import(/* webpackChunkName: "home" */ '../views/goPlay/go-play')
  },
  {
    // 剧本上传
    path: '/goUpload',
    name: 'goUpload',
    meta: { title: '剧本上传-小楼东' },
    component: () => import(/* webpackChunkName: "home" */ '../views/goUpload/go-upload')
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    meta: { title: '隐私政策-小楼东' },
    component: () => import('@/views/agreement/privacy-policy')
  },
  {
    path: '/user-agreement',
    name: 'userAgreement',
    meta: { title: '用户协议-小楼东' },
    component: () => import('@/views/agreement/user-agreement')
  }
];

const router = new VueRouter({
  // mode: 'history',
  routes
});
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
// push
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

// replace
const VueRouterReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(to) {
  return VueRouterReplace.call(this, to).catch((err) => err);
};
export default router;
