import axios from 'axios';
import { Message } from 'element-ui';
export const baseURL = process.env.VUE_APP_API_URL;
import { getToken, removeToken } from '@/utils/auth';
console.log(baseURL);
/**
 * 异常处理程序
 */

/**
 * 配置request请求时的默认参数
 */
const request = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8'
  },
  timeout: 30000 // 请求超时时间
});

request.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

/**
 * 对于状态码实际是 200 的错误
 */
request.interceptors.response.use(
  (response) => {
    const { data, config } = response;
    const { code, message } = data;
    const { responseType, nomsg } = config;
    if (responseType == 'blob') {
      return data;
    }
    if (code !== '000000') {
      // 重复点击
      if (code == '909998') {
        data.message = '';
      }
      if (!nomsg && code != '909998') {
        Message(message || '当前网络异常，请稍后重试');
      }
      if (['100001'].includes(code)) {
        removeToken();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
      return Promise.reject(data);
    }
    return data;
  },
  (error) => {
    const { response } = error || {};
    const { data } = response || {};
    const { code, message } = data || {};
    if (code !== '000000') {
      Message(message || '当前网络异常，请稍后重试');
      if (['100001'].includes(code)) {
        removeToken();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
      return Promise.reject(data);
    }
    return Promise.reject(error);
  }
);

export default request;
